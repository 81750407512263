.video {
  border-radius: 24px;
  color: white;
  overflow: hidden;
  width: calc(100% - 20px);
  margin: auto;
}

@media (min-width: 1230px) {
  .video {
    max-height: 646px;
  }
}
